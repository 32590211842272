import { Button, ButtonGroup } from "@material-ui/core";
import { Add, Remove } from "@material-ui/icons";
import styled from "styled-components";
import { useState, useEffect, useRef, forwardRef } from "react";
import { colors } from "../res/values/values";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as cartActions from "../redux/actions/cartActions";
import * as userActions from "../redux/actions/userActions";
import * as productActions from "../redux/actions/productActions";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import OrcaModal from "./Modal/OrcaModal";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import FavoriteIcon from '@mui/icons-material/FavoriteBorder';
import SelectedFavoriteIcon from '@mui/icons-material/Favorite';
import { checkBlockedUnit,initialUnit,checkDeliveryDate } from "../utilities/helpers";
import { height } from "@mui/system";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

//#region Card styles
const Container = styled.div`
  border: 0.3px solid #E6E6E6 ;
  border-radius:2px ;
  max-width: 15rem;
  margin: 3px;
  transition: all 0.2s ease;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  &:hover {
    box-shadow: 0 2px 10px 1px rgb(0 0 0 / 5%);
  }
  @media only screen and (min-width: 1400px) {
    max-width: 16rem;
  }
  @media only screen and (min-width: 1780px) {
    max-width: 17rem;
  }
`;
const ImageDiv = styled.div`
  display:flex ;
  flex:1 ;
  flex-direction:row ;
  width:100% ;
  height:8.2rem ;
  justify-content:center;
  margin-bottom:0.5rem ;
`;
const Image = styled.img`
  height: 8rem;
  width: 70%;
  min-height: 8rem;
  object-fit: contain;
`;
const SaleImage = styled.img`
  height: 2rem;
  width: 2rem;
  min-height: 2rem;
  object-fit: contain;
`;
const Details = styled.div`
  display: flex;
  flex-direction: column;
`;
const Title = styled.span`
  height: 2.5rem;
  min-height: 2.5rem;
  font-size: 0.95rem;
  font-weight: 700;
  text-align: center;
  margin: 0.2rem;
  color: black;
`;
const CardProductInfo = styled.span`
  font-size: 0.8rem;
  font-weight: 500;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
  margin-bottom: 0.2rem;
  margin-left: 0.5rem;
  color: black;
`;
const PriceDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
`;
const PriceDiv2 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;
const StockDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 1px;
`;
const Stock = styled.span`
  font-size: 0.8rem;
  font-weight: 500;
  margin-right: 5px;
  color: ${(props) => (props.stockqty > 0 ? "darkgreen" : "darkred")};
`;
const Price = styled.span`
  text-align: left;
  padding: 1px;
  font-size: 1em;
  font-weight: 500;
  color: ${(props) =>
    props.deliveryMethod === "Delivery" ? "darkgreen" : "darkred"};
  color: ${colors.primaryColor};
`;
const ColPrice = styled.span`
  text-align: left;
  padding: 1px;
  font-size: 1em;
  font-weight: ${(props) =>
    props.deliveryMethod === "Collection" ? 600 : 500};
  color: ${(props) =>
    props.deliveryMethod === "Collection"
      ? colors.blackColor
      : props.deliveryMethod === ""
      ? colors.blackColor
      : '#b1b1b1'};
`;
const DelPrice = styled.span`
  text-align: left;
  padding: 1px;
  font-size: 1em;
  font-weight: ${(props) => (props.deliveryMethod === "Delivery" ? 600 : 500)};
  color: ${(props) =>
    props.deliveryMethod === "Delivery"
      ? colors.blackColor
      : props.deliveryMethod === ""
      ? colors.blackColor
      : '#b1b1b1'};
`;
const CartActions = styled.div`
  display: flex;
  padding: 5px;
  height: 2.5rem;
`;
const QtyInput = styled.input`
  border: 1px solid ${colors.darkcolor} ;
  width: 50%;
  color: ${colors.blackColor};
  border-radius: 1px;
  margin-right: 5px;
  padding-left: 5px;
  font-size: 1.1rem;
  text-align: center;
  -moz-appearance: textfield;
`;

//#endregion
//#region Ortak View
const ProductInfo = styled.div`
  display:flex ;
  flex:1 ;
  flex-direction:row ;
  padding-left:0.5rem ;
  padding-right:0.5rem ;
  min-height: 1.8rem;
  width:100% ;
  justify-content:space-between ;
  align-items:center ;
  background-color: ${(props) => (props.offerEndDate ===''  ? "#fbfbfb": colors.errorcolor)};
  font-weight: 700;
  font-size: 0.8rem;
  color:${(props) => (props.offerEndDate !==''  ? "#fbfbfb": colors.errorcolor)} ;
`;
const NewLabel = styled.div`
  font-weight: 800;
  font-size: 0.9rem;
`;
const SaleInfo = styled.div`
  font-weight: 700;
  font-size: 0.7rem;
  position: absolute;
  margin-top: 1.8rem;
  top: 0;
  left: 1;
  z-index: 4;
  text-align: center;
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
`;
const QuantityInfo = styled.div`
  font-weight: 700;
  font-size: 0.9rem;
  margin-top: 2.5rem;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 5;
  text-align: center;
  box-shadow: 1px 1px rgba(0, 0, 0, 0.3);
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  overflow: hidden;
  color: ${colors.primaryColor};
  background-color: ${colors.blackColor};
`;
const FavoriteInfo = styled.div`
  margin-top: 1.7rem;
  margin-right: 0.4rem;
  top: 0;
  right: 0;
  position: absolute;
  z-index: 5;
  text-align: center;
  color: ${colors.primaryColor};
`;
const DeliveryMethodDiv = styled.div`
  align-items: center;
  width: 20rem;
  & div:hover {
    background-color: ${colors.primaryColor};
  }
`;
const WaitDiv = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & p {
    margin-top: 0.8rem;
  }
  & h3 {
    text-align: center;
    margin-top: 0.8rem;
    color: ${colors.primaryColor};
  }
`;
const DeliveryMethodButton = styled.div`
  font-size: 1.2rem;
  font-weight: 600;
  letter-spacing: 1px;
  align-items: center;
  text-align: center;
  color: ${colors.blackColor};
  margin: 1rem;
  padding: 5px;
  margin-top: 5px;
  background-color: ${colors.lightcolor};
`;
//#endregion
//#region List Styles
const ContainerListView = styled.div`
  border: 0.5px solid #E6E6E6 ;
  border-radius: 5px;
  margin-top: 0.9rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  overflow: hidden;
  transition: all 0.2s ease;
  &:hover {
    box-shadow: 0 2px 10px 1px rgb(0 0 0 / 5%);
  }
`;
const ContentListView = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  transition: all 0.2s ease;
  padding: 0.5rem;
  @media only screen and (min-width: 920px) {
    flex-direction: row;
    align-items: center;
  }
`;
const ImageDivListView = styled.div`
  display:flex ;
  flex:1 ;
  flex-direction:column ;
  height:100% ;
  justify-content:center;
`;
const ImageListView = styled.img`
  height: 7rem;
  width: 7rem;
  min-width: 7rem;
  object-fit: contain;
`;
const DetailsListView = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const LeftContentListView = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  @media only screen and (min-width: 920px) {
    width: 60%;
  }
`;
const TitleListView = styled.span`
  font-size: 1rem;
  font-weight: 500;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 0.5rem;
  color: ${colors.primaryColor};
`;
const ProductInformationListView = styled.span`
  font-size: 0.7rem;
  font-weight: 500;
  padding-left: 5px;
  padding-right: 5px;
`;
const InfoView = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 1.4rem;
  min-height: 1.4rem;
`;
const InfoTextListView = styled.div`
  font-size: 0.6rem;
  font-weight: 400;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
`;
const PriceDivListView = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;
const PriceListView = styled.h4`
  text-align: left;
  padding: 5px;
  font-size: 1em;
  font-weight: 600;
  color: ${colors.primaryColor};
`;
const OldPriceListView = styled.h4`
  text-align: left;
  padding: 5px;
  font-size: 0.8em;
  font-weight: 500;
  color: #636363;
  text-decoration: line-through 2px #979797;
`;
const CartActionsListView = styled.div`
  display: flex;
  height: 2.5rem;
  margin-top:0.5rem ;
  margin-left:8px ;
`;

const QtyInputListView = styled.input`
  border: 1px solid lightgray;
  color: ${colors.primaryColor};
  padding-left: 5px;
  min-height: 2rem;
  width: 45%;
  font-size: 1.1rem;
  text-align: center;
  @media only screen and (min-width: 920px) {
    width: 5rem;
  }
`;
//#endregion
function ProductCardView(props) {
  const [qtyValue, setqtyValue] = useState(0);
  const [disableValue, setDisabled] = useState(false);
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const [unit, setUnit] = useState("");
  const [units, setUnits] = useState([]);

  const [collectionPrice, setCollectionPrice] = useState(0);
  const [itemPrice, setItemPrice] = useState(0);
  const [deliveryPrice, setDeliveryPrice] = useState(0);

  const [open, setOpen] = useState(false);
  const [deliveryError, setDeliveryError] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [defaultImage,setDefaultImage]=useState('https://www.expofoodsmidlands.co.uk/catalog/Products/noproduct.jpg')

  //001 ++ Ürünlere favori seçimi eklenerek headera favoriler sekmesi eklenecek.
  const [favorite,setFavorite] = useState(props.product.favorite)
  //001 -- Ürünlere favori seçimi eklenerek headera favoriler sekmesi eklenecek.

  //002 ++ 'Offer Ends Bilgisi Gösterme.'
  const [offerEndDate,setOfferEndDate]=useState('')
  //002 -- 'Offer Ends Bilgisi Gösterme.'
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    disableValue && setDisabled(false);
  };
  const [delMethodQty, setDelMethodQty] = useState(0);
  const [deliveryMethod, setDeliveryMethod] = useState("");
  const [addProductMethod, setAddProductMethod] = useState("");
  //let addProductMethod = "";
  useEffect(() => {
    initialUnit(unit,props.product,setUnits,setUnit,initialPrice,setQunatityFunc,props.cart);
  }, [props.cart, props.product.itemno]);

  const onButtonClickHandler = (quantity) => {
    if(checkBlockedUnit(props.product,unit)){
      setDeliveryError(true)
      setAlertMessage("blocked for selected unit!");
    }else{
      if (qtyValue > 0 || quantity > -1) {
      const access_token = localStorage.getItem("access_token");
      if (access_token) {
        setAddProductMethod("onClick");
        if (deliveryMethod === "") {
          setDelMethodQty(quantity);
          handleOpen();
        } else {
          addProductForButtonClick(quantity, deliveryMethod);
        }
      } else{
        navigate("/route=account/login", {
          state: { href: window.location.href },
        });
      }
    }
    }
    
  };
  
  const addProductForButtonClick = (quantity, method) => {
    if (quantity === -1) {
      if (qtyValue > 1) {
        props.actions.addCartApi({
          product: props.product,
          quantity: -1,
          deliveryMethod: method,
          selectedUnit: unit,
          price: method === "Collection" ? collectionPrice : deliveryPrice,
        });
      } else if (qtyValue === 1) {
        props.actions.addCartApi({
          product: props.product,
          quantity: 0,
          deliveryMethod: method,
          selectedUnit: unit,
          price: method === "Collection" ? collectionPrice : deliveryPrice,
        });
      }
    } else {
      props.actions.addCartApi({
        product: props.product,
        quantity: 1,
        deliveryMethod: method,
        selectedUnit: unit,
        price: method === "Collection" ? collectionPrice : deliveryPrice,
      });
    }
  };
  const addProductForOnChangeHandler = async (quantity, deliveryMethod) => {
    
    setDisabled(true);
    await props.actions.addCartApi({
      product: props.product,
      quantity,
      deliveryMethod,
      selectedUnit: unit,
      price: deliveryMethod === "Collection" ? collectionPrice : deliveryPrice,
    });
    setDisabled(false);
  
    //inputRef.current.focus();
  };
  const onChangeHandler = async (event) => {
    if(checkBlockedUnit(props.product,unit)){
      setDeliveryError(true)
      setAlertMessage("blocked for selected unit!");
    }else{
      let name = event.target.name;
      let value = event.target.value;
      switch (name) {
        case "qtyinput":
          const access_token = localStorage.getItem("access_token");
          if (access_token) {
            setAddProductMethod("onChangeHandler");
            if (value !== "") {
              setqtyValue(value);
            } else {
              setqtyValue("");
            }
          } else
            navigate("/route=account/login", {
              state: { href: window.location.href },
            });
  
          break;
        default:
        // code block
      }
    }
    
  };
  const onBlurHandler = async (event) => {
    let name = event.target.name;
    let value = event.target.value;
    switch (name) {
      case "qtyinput":
        const access_token = localStorage.getItem("access_token");
        if (access_token) {
          setAddProductMethod("onChangeHandler");
          if (value !== "") {
            let newQty = parseInt(value);
            let cartItem = props.cart.find(
              (c) => c.itemno === props.product.itemno
            );
            if (cartItem) {
              if (newQty === 0 || newQty < 0) {
                setDisabled(true);
                await props.actions.removeFromCart(cartItem.id);
                setDisabled(false);
                //inputRef.current.focus()
              } else {
                setDisabled(true);
                await props.actions.updateCartItem({
                  cartItem: cartItem,
                  quantity: parseInt(newQty),
                });
                setDisabled(false);
                //inputRef.current.focus()
              }
            } else {
              if (newQty > 0) {
                if (deliveryMethod === "") {
                  setDelMethodQty(parseInt(newQty));
                  handleOpen();
                } else {
                  addProductForOnChangeHandler(
                    parseInt(newQty),
                    deliveryMethod
                  );
                }
              } else {
                setqtyValue(0);
                setDisabled(false);
                //inputRef.current.focus()
              }
            }
          } else {
            setqtyValue("");
          }
        } else
          navigate("/route=account/login", {
            state: { href: window.location.href },
          });

        break;
      default:
      // code block
    }
  };
  const changeDeliveryMethod = (method) => {
    if (!checkDeliveryDate(method,props.currentUser)) {
      setDeliveryError(true);
      setAlertMessage("Your delivery days is unknown !");
    } else {
      addProductMethod === "onClick"
        ? addProductForButtonClick(delMethodQty, method)
        : addProductForOnChangeHandler(delMethodQty, method);
    }
    handleClose();
  };
  const initialPrice = async (unit, l_units) => {
    if (props.currentUser.id) {
      let g_specialprice = 0;
      let g_specialpriceEndDate = '';
      let l_deliveryPrice=0;
      
      props.product.specialprices !== "" && await Promise.all(
        
          JSON.parse(props.product.specialprices.toString()).map(async (specialprice) => {
            if (specialprice.unit === unit){
              g_specialprice = parseFloat(specialprice.unitprice);
              g_specialpriceEndDate = specialprice.endingdate
            } 
          })
      );
      await Promise.all(
        JSON.parse(props.product.prices.toString()).map((price) => {
          if (unit === price.unit) {
            l_deliveryPrice=price.unitprice
          }
        })
      );
      let baseUnitQtyPer = l_units.find((u) => u.code === props.product.baseunit).qtyPerUnit;
      let selectUnitQtyPer = l_units.find((u) => u.code === unit).qtyPerUnit;
      let unitPrice = (props.product.unitprice / baseUnitQtyPer) * selectUnitQtyPer;
      let colPrice = ( props.product.collectionprice / baseUnitQtyPer ) *selectUnitQtyPer;
      if(l_deliveryPrice === 0)  l_deliveryPrice =unitPrice;
      setItemPrice(unitPrice);
      if(g_specialprice!==0 && g_specialprice < colPrice ) 
      {
        setCollectionPrice(g_specialprice)
        setOfferEndDate(g_specialpriceEndDate)
      }  else{
        setCollectionPrice(colPrice);
        setOfferEndDate('')
      } 
      if(g_specialprice!==0 && g_specialprice < l_deliveryPrice ) {
        setDeliveryPrice(g_specialprice)
        setOfferEndDate(g_specialpriceEndDate)
      } else {
        setDeliveryPrice(l_deliveryPrice);
        setOfferEndDate('')
      }
    }
  };
  const setQunatityFunc = (unit) => {
    let cartItem = props.cart.find(
      (c) => c.itemno === props.product.itemno && c.itemunit === unit
    );
    props.cart.length > 0
      ? setDeliveryMethod(props.cart[0].deliverymethod)
      : setDeliveryMethod("");
    if (cartItem) setqtyValue(cartItem.quantity);
    else setqtyValue(0);
  };
  const handleChangeUnit = (event) => {
    initialPrice(event.target.value, units);
    setUnit(event.target.value);
    setQunatityFunc(event.target.value);
  };
  const replaceImage = (error) => {
      error.target.src = defaultImage; 
    }
  //001 ++ Ürünlere favori seçimi eklenerek headera favoriler sekmesi eklenecek.
  const controlFavoriteProduct = async (value)=>{
    const access_token = localStorage.getItem("access_token");
      if (access_token) {
        const body = {
          itemno:props.product.itemno,
          favorite: value
        }
        await productActions.controlFavoriteProductApi(body,setFavorite)
        if(!value)
          (props.refreshFavoriteList) && await props.refreshFavoriteList()
      } else{
        navigate("/route=account/login", {
          state: { href: window.location.href },
        });
      }
  }
  //001 -- Ürünlere favori seçimi eklenerek headera favoriler sekmesi eklenecek.
  debugger
  console.log(collectionPrice,deliveryPrice,itemPrice,props.product.itemno);
  
  if (props.viewType === "Grid") {
    return (
      <Container qtyValue={qtyValue}>
        <OrcaModal isOpen={open} onClose={handleClose}>
          <DeliveryMethodDiv>
            <DeliveryMethodButton
              onClick={() => changeDeliveryMethod("Delivery")}
            >
              Delivery{" "}
            </DeliveryMethodButton>
            <DeliveryMethodButton
              onClick={() => changeDeliveryMethod("Collection")}
            >
              Collection{" "}
            </DeliveryMethodButton>
          </DeliveryMethodDiv>
        </OrcaModal>
        <OrcaModal isOpen={disableValue} onClose={() => setDisabled(false)}>
          <WaitDiv>
            <CircularProgress size={40} thickness={5} />
            <h3>PROCESS CONTINUING</h3>
            <p>Please wait ...</p>
          </WaitDiv>
        </OrcaModal>
        <ProductInfo offerEndDate={offerEndDate}>
          <NewLabel >{props.product.newitem && 'NEW'  } </NewLabel>
           <div>{offerEndDate!=='' && 'OFFER ENDS '+offerEndDate}</div> 
           <div></div>
        </ProductInfo>
        <SaleInfo>
          {(deliveryMethod === "Collection" &&itemPrice - collectionPrice > 0.05) ||(deliveryMethod === "Delivery" && itemPrice - deliveryPrice > 0.05)
            ? <SaleImage src={`./images/sale.png`} />
            : ""}
        </SaleInfo>
        <FavoriteInfo onClick={()=>controlFavoriteProduct(!favorite)}>
          { favorite ? <SelectedFavoriteIcon sx={{width:'1.7rem',height:'1.7rem',color:`${colors.secondryColor}`}}/> : <FavoriteIcon sx={{width:'1.7rem',height:'1.7rem'}}/> } 
        </FavoriteInfo>
        <ImageDiv>
          <Image
          onClick={() => {
            let desc = props.product.description.replace(/ /g, "-");
            desc = desc.replace(/%/g, "");
            desc = desc.replace('/', "");
            navigate(`/route=product/card/${props.product.itemno}-${desc}`);
          }}
          src={`./${props.product.image}`}
          alt={`${props.product.description}`}
          onError={replaceImage}
          //source={{uri:'' }}
        />
        </ImageDiv>
        
        <Details>
          <Title>{props.product.description} </Title>
          <FormControl
            variant="standard"
            sx={{ m: 1, minWidth: 120 }}
            size="small"
          >
            <InputLabel id="demo-select-small">Unit</InputLabel>
            <Select
              labelId="demo-select-small"
              id="demo-select-small"
              sx={{ color:'#AAA',border: "1px solid #E6E6E6", }}
              value={unit}
              label="Unit"
              onChange={handleChangeUnit}
            >
              {units.map((u) => {
                return (
                  <MenuItem key={u.code} value={u.code}>
                    {u.display} 
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {props.settings.pricevisible && props.currentUser.id && (
            <PriceDiv>
              <PriceDiv2>
                <ColPrice deliveryMethod={deliveryMethod}>
                  Collection: £ {collectionPrice.toFixed(2)}
                </ColPrice>
                {itemPrice - collectionPrice > 0.05 && (
                  <OldPriceListView>£ {itemPrice.toFixed(2)}</OldPriceListView>
                )}
              </PriceDiv2>
              <PriceDiv2>
                <DelPrice deliveryMethod={deliveryMethod}>
                  Delivery: £ {
                  deliveryPrice.toFixed(2)
                  }
                </DelPrice>
                {itemPrice - deliveryPrice > 0.05 && (
                  <OldPriceListView>£ {itemPrice.toFixed(2)}</OldPriceListView>
                )}
              </PriceDiv2>
            </PriceDiv>
          )}

          <CartActions>
            <QtyInput
              name="qtyinput"
              onChange={onChangeHandler}
              onBlur={onBlurHandler}
              type="number"
              value={qtyValue}
              disabled={disableValue}
              ref={inputRef}
            />
            <ButtonGroup variant="outlined" aria-label="outlined button group">
              <Button
                style={{ borderRadius: "1px",border:`1px solid ${colors.darkcolor}`}}
                onClick={() => {
                  onButtonClickHandler(-1);
                }}
              >
                <Remove
                  style={{
                    color:`${colors.blackColor}`,
                    flex: "1",
                    height: "100%",
                  }}
                />
              </Button>
              <Button
                style={{ borderRadius: "1px",border:`1px solid ${colors.darkcolor}`}}
                onClick={() => {
                  onButtonClickHandler(1);
                }}
              >
                <Add
                  style={{
                    color:`${colors.blackColor}`,
                    flex: "1",
                    height: "100%",
                  }}
                />
              </Button>
            </ButtonGroup>
          </CartActions>
          <StockDiv>
            {props.product.stockqty > 0 ? (
              <Stock stockqty={props.product.stockqty}>
                In Stock
              </Stock>
            ) : (
              <Stock stockqty={props.product.stockqty}>Out Of Stock</Stock>
            )}
          </StockDiv>
        </Details>
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            open={deliveryError}
            autoHideDuration={1500}
            onClose={() => setDeliveryError(false)}
          >
            <Alert
              onClose={() => setDeliveryError(false)}
              severity="error"
              sx={{ width: "100%", padding: "14px 12px" }}
            >
              {alertMessage}
            </Alert>
          </Snackbar>
        </Stack>
      </Container>
    );
  } else {
    return (
      <ContainerListView qtyValue={qtyValue}>
        <OrcaModal isOpen={open} onClose={handleClose}>
          <DeliveryMethodDiv>
            <DeliveryMethodButton
              onClick={() => changeDeliveryMethod("Delivery")}
            >
              Delivery{" "}
            </DeliveryMethodButton>
            <DeliveryMethodButton
              onClick={() => changeDeliveryMethod("Collection")}
            >
              Collection{" "}
            </DeliveryMethodButton>
          </DeliveryMethodDiv>
        </OrcaModal>
        <OrcaModal isOpen={disableValue} onClose={() => setDisabled(false)}>
          <WaitDiv>
            <CircularProgress size={40} thickness={5} />
            <h3>PROCESS CONTINUING</h3>
            <p>Please wait ...</p>
          </WaitDiv>
        </OrcaModal>
        <ProductInfo offerEndDate={offerEndDate}>
          <NewLabel >{props.product.newitem && 'NEW'  } </NewLabel>
           <div>{offerEndDate!=='' && 'OFFER ENDS '+offerEndDate}</div> 
          <div></div>
        </ProductInfo>
        <SaleInfo>
          {(deliveryMethod === "Collection" &&itemPrice - collectionPrice > 0.05) ||(deliveryMethod === "Delivery" && itemPrice - deliveryPrice > 0.05)
            ? <SaleImage src={`./images/sale.png`} />
            : ""}
        </SaleInfo>
        <FavoriteInfo onClick={()=>controlFavoriteProduct(!favorite)}>
          { favorite ? <SelectedFavoriteIcon sx={{width:'1.7rem',height:'1.7rem',color:`${colors.secondryColor}`}}/> : <FavoriteIcon sx={{width:'1.7rem',height:'1.7rem'}}/> } 
        </FavoriteInfo>
        <ContentListView>
          <LeftContentListView>
            <ImageDivListView>
              <ImageListView
              onClick={() => {
                let desc = props.product.description.replace(/ /g, "-");
                desc = desc.replace(/%/g, "");
                desc = desc.replace('/', "");
                navigate(`/route=product/card/${props.product.itemno}-${desc}`);
              }}
              src={`./${props.product.image}`}
              alt={`${props.product.description}`}
              onError={replaceImage}
            />
            </ImageDivListView>
            

            <DetailsListView>
              <TitleListView>{props.product.description}</TitleListView>

              <ProductInformationListView>
                Size : {props.product.unitsize !== "" && props.product.packsize}
                *{props.product.packsize !== "" && props.product.unitsize} •{" "}
                Unit :{" "}
                {props.product.salesunit !== "" && props.product.salesunit} •{" "}
                Vat % :{props.product.vat && props.product.vat} • Stock Qty :{" "}
                {props.product.stockqty &&
                  (props.product.stockqty < 0 ? 0 : props.product.stockqty)}
              </ProductInformationListView>
              {props.product.stockqty > 0 ? (
                <InfoView border={true}>
                  <InfoTextListView
                    style={{
                      color: "darkgreen",
                      textAlign: "right",
                      fontWeight: "500",
                    }}
                  >
                    In Stock
                  </InfoTextListView>
                </InfoView>
              ) : (
                <InfoView border={true}>
                  <InfoTextListView
                    style={{
                      color: "darkred",
                      textAlign: "right",
                      fontWeight: "500",
                    }}
                  >
                    Out Of Stock
                  </InfoTextListView>
                </InfoView>
              )}
             
              {props.settings.pricevisible && props.currentUser.id && (
                <PriceDiv>
                  <PriceDiv2>
                    <ColPrice deliveryMethod={deliveryMethod}>
                      Collection: £ {collectionPrice.toFixed(2)}
                    </ColPrice>
                    {itemPrice - collectionPrice > 0.05 && (
                      <OldPriceListView>
                        £ {itemPrice.toFixed(2)}
                      </OldPriceListView>
                    )}
                  </PriceDiv2>
                  <PriceDiv2>
                    <DelPrice deliveryMethod={deliveryMethod}>
                      Delivery: £ {deliveryPrice.toFixed(2)}
                    </DelPrice>
                    {itemPrice - deliveryPrice > 0.05 && (
                      <OldPriceListView>
                        £ {itemPrice.toFixed(2)}
                      </OldPriceListView>
                    )}
                  </PriceDiv2>
                </PriceDiv>
              )}
            </DetailsListView>
          </LeftContentListView>
          <FormControl
            variant="standard"
            sx={{ m: 1, minWidth: 200 }}
            size="small"
          >
            <InputLabel id="demo-select-small">Unit</InputLabel>
            <Select
              labelId="demo-select-small"
              id="demo-select-small"
              sx={{ color:'#AAA',border: "1px solid #E6E6E6", }}
              value={unit}
              label="Unit"
              onChange={handleChangeUnit}
            >
              {units.map((u) => {
                return (
                  <MenuItem key={u.code} value={u.code}>
                    {u.display} 
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <CartActionsListView>
            <Button
              style={{ border: "1px solid lightgray", borderRadius: "0" }}
              onClick={() => {
                onButtonClickHandler(-1);
              }}
            >
              <Remove style={{ color: colors.primaryColor }} />
            </Button>

            <QtyInputListView
              name="qtyinput"
              onChange={onChangeHandler}
              onBlur={onBlurHandler}
              type="number"
              value={qtyValue}
              disabled={disableValue}
              ref={inputRef}
            />

            <Button
              style={{ border: "1px solid lightgray", borderRadius: "0" }}
              onClick={() => {
                onButtonClickHandler(1);
              }}
            >
              <Add style={{ color: colors.primaryColor }} />
            </Button>
          </CartActionsListView>
        </ContentListView>
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            open={deliveryError}
            autoHideDuration={1500}
            onClose={() => setDeliveryError(false)}
          >
            <Alert
              onClose={() => setDeliveryError(false)}
              severity="error"
              sx={{ width: "100%", padding: "14px 12px" }}
            >
              {alertMessage}
            </Alert>
          </Snackbar>
        </Stack>
      </ContainerListView>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    actions: {
      addCartApi: bindActionCreators(cartActions.addCart, dispatch),
      updateCartItem: bindActionCreators(cartActions.updateCartItem, dispatch),
      removeFromCart: bindActionCreators(cartActions.removeFromCart, dispatch),
      changeDeliveryMethod: bindActionCreators(
        userActions.changeDeliveryMethod,
        dispatch
      ),
    },
  };
}
function mapStateToProps(state) {
  return {
    cart: state.cartActionReducer,
    settings: state.settingReducer,
    currentUser: state.currentUserReducer,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ProductCardView);
